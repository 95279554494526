import AES from 'crypto-js/aes';
import ECB from 'crypto-js/mode-ecb';
import encHex from 'crypto-js/enc-hex';
import Pkcs7 from 'crypto-js/pad-pkcs7';
import encUtf8 from 'crypto-js/enc-utf8';

import { message } from 'antd';
import { TUser } from '@gf-tech/types';
import { navigateToUrl } from 'single-spa';
import { EventBus, Log } from '@gf-tech/utils';
import { logout, store, unlockValidate } from '../../utils';

export default class ScreenLocker {
    constructor() {
        this.init();
    }

    worker: MessagePort = null;

    lockView = document.getElementById('app-lock-view');

    encrypt(str) {
        var encrypted = AES.encrypt(str, encUtf8.parse('13a586227b2a9a3d'), {
            mode: ECB,
            padding: Pkcs7,
        });
        return encHex.stringify(encrypted.ciphertext);
    }

    init() {
        this.worker = new SharedWorker('/worker/screenLock.js').port;
        window.addEventListener('load', () => this.bindEvent());
        this.worker.postMessage('registe');
        if (store.get('screenLock')) this.lock();
        const userInfo = store.get('userInfo') as TUser;
        if (userInfo) this.worker.postMessage(userInfo.screenLock);
        window.addEventListener('unload', () =>
            this.worker.postMessage('logout')
        );
    }

    bindEvent() {
        var _self = this;
        var unlockBtn = document.getElementById(
            'unlock-btn'
        ) as HTMLButtonElement;
        var unlockInput = document.getElementById(
            'unlock-input'
        ) as HTMLInputElement;
        var unlockEye = document.getElementById('unlock-eye');
        var unlockEyeInvisible = document.getElementById(
            'unlock-eye-invisible'
        );
        document
            .getElementById('logout-btn')
            .addEventListener('click', async () => {
                await logout();
                store.remove('tabs');
                store.remove('token');
                store.remove('userInfo');
                store.remove('screenLock');
                this.lockView.style.display = 'none';
                EventBus.dispatch('app:ws-disconnect', null);
                navigateToUrl('/login');
            });

        var unlockHandler = () =>
            unlockValidate(this.encrypt(unlockInput.value)).then(res => {
                if (res) {
                    unlockInput.value = '';
                    unlockBtn.setAttribute('disabled', 'disabled');
                    _self.worker.postMessage('unlock');
                } else {
                    message.error('密码错误，请重新输入');
                }
            });

        unlockEye.addEventListener('click', () => {
            unlockEyeInvisible.style.display = 'block';
            unlockEye.style.display = 'none';
            unlockInput.setAttribute('type', 'password');
        });
        unlockEyeInvisible.addEventListener('click', () => {
            unlockEyeInvisible.style.display = 'none';
            unlockEye.style.display = 'block';
            unlockInput.setAttribute('type', 'text');
        });

        unlockInput.addEventListener('input', () => {
            if (unlockInput.value) {
                unlockBtn.removeAttribute('disabled');
            } else {
                unlockBtn.setAttribute('disabled', 'disabled');
            }
        });
        unlockInput.addEventListener('keyup', e => {
            if (e.key === 'Enter') unlockHandler();
        });

        unlockBtn.addEventListener('click', () => unlockHandler());

        var lastTime = 0;
        window.addEventListener('mousemove', () => {
            var now = Date.now();
            if (now - lastTime < 5000) {
                lastTime = now;
            } else {
                lastTime = now;
                this.worker.postMessage('resetTimmer');
            }
        });

        this.worker.onmessage = function (e) {
            Log.warn('多tab页同时更新可能存在性能问题，下个版本使用进行优化');
            switch (e.data.type) {
                case 'lock':
                    _self.lock();
                    store.set('screenLock', true);
                    break;
                case 'unlock':
                    store.set('screenLock', false);
                    _self.lockView.style.display = 'none';
            }
        };
    }

    lock() {
        if (location.pathname !== '/login') {
            this.lockView.style.display = 'flex';
        }
    }
}
