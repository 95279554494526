import { Component } from 'react';
import { AppProps } from 'single-spa';
import { TAppConf, TResource, TUser } from '@gf-tech/types';
import { http, store } from './utils';
import { RootRouter } from './components/RootRouter';
import styled from 'styled-components';
type TState = {
    router: TResource;
};
const RootStyle = styled.div`
    .react-resizable {
        position: relative;
    }
    .react-resizable-handle {
        /* position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
        background-position: bottom right;
        padding: 0 3px 3px 0; */
        position: absolute;
        top: 0;
        right: 0;
        width: 4px;
        height: 100%;
    }
    .react-resizable-handle-sw {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
        transform: rotate(90deg);
    }
    .react-resizable-handle-se {
        bottom: 0;
        right: 0;
        cursor: se-resize;
    }
    .react-resizable-handle-nw {
        top: 0;
        left: 0;
        cursor: nw-resize;
        transform: rotate(180deg);
    }
    .react-resizable-handle-ne {
        top: 0;
        right: 0;
        cursor: ne-resize;
        transform: rotate(270deg);
    }
    .react-resizable-handle-w,
    .react-resizable-handle-e {
        /* top: 50%; */
        /* margin-top: -10px; */
        cursor: ew-resize;
    }
    .react-resizable-handle-w {
        left: 0;
        transform: rotate(135deg);
    }
    .react-resizable-handle-e {
        /* right: 0; */
        right: -5px;
        /* transform: rotate(315deg); */
    }
    .react-resizable-handle-n,
    .react-resizable-handle-s {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
    }
    .react-resizable-handle-n {
        left: 0;
        /* top: 0;
        transform: rotate(225deg); */
        width: 100%;
        height: 4px;
    }
    .react-resizable-handle-s {
        bottom: 0;
        transform: rotate(45deg);
    }
    flex: 1;
    width: 100%;
    margin: var(--app-var-24);
    margin-bottom: 0;
    padding: 24px;
    padding-top: 8px;
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    height: calc(100% - var(--app-var-24));
    .ant-table-cell {
        .ant-btn-link {
            padding: 0 0;
            height: auto;
            + .ant-btn-link {
                margin-left: 8px;
            }
        }
    }
`;
export default class Root extends Component<AppProps & TAppConf, TState> {
    constructor(props: AppProps & TAppConf) {
        super(props);

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ baseURL: appConf.apiHost, store });

        const userInfo: TUser = store.get('userInfo');
        const bcp = userInfo.resList.find(item => item.uriRoute === '/bcp');
        //筛选掉type===3的路由
        bcp.childList.forEach(v => {
            if (v.uriRoute === '/manage') {
                v.childList = v.childList.filter(it => it.type !== 3);
            }
        });
        this.state = {
            router: bcp,
        };
    }
    render() {
        return (
            <RootStyle className='root-wrap'>
                <RootRouter base='/bcp' router={this.state.router}></RootRouter>
            </RootStyle>
        );
    }
}
